import Error404 from "components/routes/NotFound";
import { useEffect } from "react";
import { reloadIfNewVersion } from "feature/appVersion";


export const ModuleLoaderError = (props: unknown) => {
  useEffect(reloadIfNewVersion, []);

  return (
    <Error404 />
  );
};
