// front-app/config-overrides.js
// @ts-ignore
window.APP_VERSION_DATE = APP_VERSION_DATE;

// @ts-ignore
export const getAppVersion = () => APP_VERSION_DATE as string;

const storageKey = "lastReloadOnVersion";
export const reloadIfNewVersion = () => {
  const prevVersion = localStorage.getItem(storageKey);
  const currentVersion = getAppVersion();

  if (prevVersion == currentVersion) return;
  localStorage.setItem(storageKey, currentVersion);
  window.location.reload();
};
