import { getBaseAxios } from "services/axios";
import { licenseStore } from "domain/license/licenseStore";
import { createLicenseApi } from "@gs/core/domain/License/createLicenseApi";
import type { License, LicenseId } from "@gs/core/domain/License/License";

export const licenseApi = (() => {
  const axiosInstance = getBaseAxios("idV2");

  const coreApi = createLicenseApi(axiosInstance);

  const getLicenses: typeof coreApi.getLicenses = async (...args) => {
    const result = await coreApi.getLicenses(...args);

    licenseStore.setState(
      result.data.data.reduce<Record<LicenseId, License>>((acc, license) => {
        acc[license.uuid] = license;
        return acc;
      }, {}),
    );

    return result;
  };

  const getLicense: typeof coreApi.getLicense = async (...args) => {
    const result = await coreApi.getLicense(...args);

    licenseStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };


  return {
    getLicenses,
    getLicense,
    getGroupCountsOfLicense: coreApi.getGroupCountsOfLicense,
  };
})();
