import { Input as AntdInput } from "antd";
import type { InputProps as AntdInputProps, InputRef as AntdInputRef } from "antd";
import React, { forwardRef } from "react";


export type InputProps = AntdInputProps
export type InputRef = AntdInputRef
export const InputFC = forwardRef<InputRef, InputProps>((props, ref) => {
  return (
    <AntdInput
      {...props}
      ref={ref}
    />
  );
});

export const Input: typeof AntdInput = Object.assign({}, AntdInput, InputFC);
