import type { CSSProperties } from "react";
import { useMemo } from "react";
import { Popover } from "@gs/uiKit/dataDisplay/Popover";
import { ProfileMenuContent } from "./ProfileMenuContent";
import { UserAvatar } from "domain/user/component/UserAvatar";
import { useCurrentUser } from "feature/session/hook/useCurrentUser";
import { mainLayoutService } from "feature/mainLayout/MainLayoutService";
import { Flex } from "@gs/uiKit/layout/Flex";
import { Typography } from "@gs/uiKit/general/Typography";
import { RightOutlined } from "@ant-design/icons";

export const ProfileMenu = (props: { className?: string; style?: CSSProperties }) => {
  const isSideMenuOpen = mainLayoutService.useStore((s) => s.isSideMenuOpen);
  const profileMenuContent = useMemo(() => <ProfileMenuContent />, []);
  const { currentUser } = useCurrentUser();

  if (!currentUser) {
    console.warn("Tried to render ProfileMenu without user");
    return null;
  }

  return (
    <Popover
      content={profileMenuContent}
      arrow={false}
      placement="rightBottom"
    >
      <Flex
        gap="middle"
        align="center"
        className={props.className}
        style={{
          width: isSideMenuOpen ? "100%" : undefined,
          padding: isSideMenuOpen ? "0 var(--padding-sm)" : undefined,
          ...props.style,
        }}
      >
        <UserAvatar
          userId={currentUser.id}
          shape="square"
        />
        {isSideMenuOpen && (
          <>
            <Typography.Text
              ellipsis
              style={{ flex: "1 1 0" }}
            >
              {currentUser.first_name}
              {" "}
              {currentUser.last_name}
            </Typography.Text>

            <RightOutlined />
          </>
        )}
      </Flex>
    </Popover>
  );
};
