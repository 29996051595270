import type { LeadId } from "@gs/core/domain/Lead/Lead";

export const leadIdParamName = "leadId";
const secondLevelPath = `:${leadIdParamName}?`;
export const messengerPath = `/messenger/${secondLevelPath}`;

export const getLinkToMessenger = (leadId?: LeadId) => {
  return messengerPath.replace(`${secondLevelPath}`, leadId || "");
};

export default messengerPath;
