import type { IsoDate, Mixed, MixedNumberOrDate } from "../../commonTypes";
import type { License, LicenseId } from "./License";
import type { TeamId } from "../Team/Team";
import type { SubscriptionId } from "../Subscription/Subscription";
import type { LicenseVendor } from "./LicenseVendor";
import type { LicenseStatus } from "./LicenseStatus";
import type { PlanId } from "../Plan/Plan";

export type LicenseFilter = {
  id?: Mixed<LicenseId>
  appliedTeamId?: Mixed<TeamId>
  subscriptionId?: Mixed<SubscriptionId>
  vendor?: Mixed<LicenseVendor>
  key?: Mixed<License["key"]>
  planId?: Mixed<PlanId>
  status?: Mixed<LicenseStatus>
  nextCreditTopUpAt?: MixedNumberOrDate<IsoDate>
  expireAt?: MixedNumberOrDate<IsoDate>
  createdAt?: MixedNumberOrDate<IsoDate>
  updatedAt?: MixedNumberOrDate<IsoDate>
}

export const licenseFilterToServerFormat = (filter?: LicenseFilter) => filter && ({
  uuid: filter.id,
  applied_team_id: filter.appliedTeamId,
  subscription_id: filter.subscriptionId,
  vendor: filter.vendor,
  key: filter.key,
  plan_id: filter.planId,
  status: filter.status,
  next_credit_top_up_at: filter.nextCreditTopUpAt,
  expire_at: filter.expireAt,
  created_at: filter.createdAt,
  updated_at: filter.updatedAt,
});
