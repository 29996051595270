import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

export const AsyncMessengerPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'messengerPage' */ "./MessengerPage")
      .catch( (e) => {
        console.log("Page loading err", e);
        return Error404;
      } );
  },
  // @ts-ignore
  loading: Loading,
});


