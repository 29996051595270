import { useState, useEffect, useRef } from "react";
import { Navigation } from "./Nav";
import { AboutTemplate } from "./AboutTemplate";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import createModal from "components/hocs/ModalTrigger";
import Icon from "../../../../../common/elements/Icon";
import Button from "components/common/buttons/Button";
import Preloader from "components/common/elements/Preloader";
import type { IChooseTemplateProps } from "../interfaces";
import { paths } from "components/routes/flowsGroup/const";


import styles from "./Modal.module.scss";
import { templateCategories } from "../const";
import FlowDetailsPage from "../../Details";
import { parseFlow } from "../../Details/reducer/actions";
import { ModalContent as CloneModalContent } from "../../NewOrEditModal/Content";
import Modal from "../../../../../common/Modal/Modal";
import { flowDetailsSelector, workTableSelector } from "../../Details/reducer/selectors";
import { filters as getFilters } from "../../List/reducer/selectors";
import isUndefined from "lodash/isUndefined";
import { useCurrentUser } from "feature/session/hook/useCurrentUser";

type TParams = {
  templateId: string
}

export const ChooseTemplateContent = ({ onRequestClose, onConstructorHover }: IChooseTemplateProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const filter = useSelector(getFilters);
  const workspaceUui = filter.flow_workspace_uuid === "is_null" ? undefined : filter.flow_workspace_uuid;
  const flowContainer = useRef<HTMLDivElement>(null);
  const { templateId } = useParams<TParams>();
  const isUnauthStatus = useCurrentUser().currentUserStatus === "unauthorized";
  const [cloneTemplateModal, setCloneTemplateModal] = useState(false);
  const editedTemplate = useSelector(workTableSelector)?.flow;
  const newTemplate = editedTemplate && { ...editedTemplate, flow_workspace_uuid: workspaceUui, version: { ...editedTemplate.version, nodes: editedTemplate.nodes } };

  const flowDetails = useSelector(flowDetailsSelector);
  const isInitStore = !isUndefined(flowDetails);

  let template: any = null;
  templateCategories.forEach((item) => {
    // @ts-ignore
    const findTemplate = item.templatesList.find((template) => template.uuid === templateId);
    if (findTemplate) {
      template = findTemplate;
    }
  });
  const flow = template?.jsonTemplate;

  useEffect(() => {
    flow && isInitStore && dispatch(parseFlow(flow));
  }, [templateId, isInitStore]);

  const handleUseTemplate = () => {
    if (flow) {
      setCloneTemplateModal(true);
    }
  };

  const redirectToLogin = () => {
    return history.replace("/");
  };

  const onCloneTemplate = () => {
    onRequestClose();
    setCloneTemplateModal(false);
  };

  return (
    <div className={styles.Modal}>
      <Modal
        isOpen={cloneTemplateModal}
        closeModal={() => setCloneTemplateModal(false)}
      >
        <CloneModalContent
          onRequestClose={() => setCloneTemplateModal(false)}
          isClone={true}
          flow={newTemplate}
          isTemplate
          onCloneTemplate={() => onCloneTemplate()}
        />
      </Modal>
      <div className={styles.ModalLeft}>
        <Navigation />
      </div>
      <div className={styles.ModalRight}>
        <div
          className={styles.ModalCloseBtn}
          onClick={() => onRequestClose()}
        >
          <Icon
            icon="Cross"
            color='#516F90'
            size={20}
          />
        </div>
        <div className={styles.ModalTopRow}>
          <Button
            green
            onClick={isUnauthStatus ? redirectToLogin : handleUseTemplate}
          >
            Use Template
          </Button>
        </div>
        <div
          onMouseEnter={() => onConstructorHover?.(true)}
          onMouseLeave={() => onConstructorHover?.(false)}
          className={styles.ModalFlow}
          ref={flowContainer}
        >
          {!flow && <Preloader />}
          {flow && (
            // @ts-ignore
            <FlowDetailsPage
              localFlow={flow}
              isTemplate
              match={{ params: { id: flow.uuid, subRoute: undefined } }}
              controlClass={styles.ControlBtns}
            />
          )}
        </div>
        <AboutTemplate />
      </div>
    </div>
  );
};

export const ModalChooseTemplate = createModal(ChooseTemplateContent);

export const ModalChooseTemplateContainer = () => {
  const [hoverMode, setHoverMode] = useState<boolean>(false);
  const history = useHistory();
  const { templateId } = useParams<TParams>();

  const handleClose = () => {
    history.replace(paths.automation);
  };


  return (
    <ModalChooseTemplate
      modalClassName={styles.ModalWrap}
      onConstructorHover={setHoverMode}
      isOpen={templateId}
      onClose={handleClose}
      modalCustomStyles={{
        overlay: {
          overflow: hoverMode ? "hidden" : "",
        },
      }}
    />
  );
};

