import styles from "./SettingsNavigation.module.scss";
import { InnerNavLink } from "components/common/routing/InnerNavLink";
import { settingsSubRoutes } from "../subRoutes";
import { Button } from "@gs/uiKit/general/Button";
import { InnerLink } from "components/common/routing/InnerLink";
import { path as userPath } from "components/routes/UserSettingsGroup/User";

export const SettingsNavigation = () => {
  return (
    <div className={styles.container}>
      <div className="gs-ph gs-pv-sm gs-fw-600">
        Team settings
      </div>
      <div className="gs-grow">
        {settingsSubRoutes.map((route) => (
          <InnerNavLink
            to={route.to || route.path}
            activeClassName="--active"
            className={styles.link}
          >
            {route.label}
          </InnerNavLink>
        ))}
      </div>
      <InnerLink to={userPath}>
        <Button
          type="link"
          className="gs-mb-sm"
        >
          Go to personal settings
        </Button>
      </InnerLink>
    </div>
  );
};
