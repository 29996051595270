import type { AxiosRequestConfig, AxiosInstance } from "axios";
import type { ApiListResponse, OrderType } from "../../commonTypes";
import type { PlanFilter } from "./PlanFilter";
import { planFilterToServerFormat } from "./PlanFilter";
import type { Plan, PlanId } from "./Plan";


export const createPlanApi = (axios: AxiosInstance) => {
  const getPlans = (
    params: {
      filter?: PlanFilter
      limit: number
      offset: number
      orderField?: string
      orderType?: OrderType
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<ApiListResponse<Plan>>(
      "/api/plans",
      {
        ...config,
        params: {
          order_field: params.orderField,
          order_type: params.orderType,
          limit: params.limit,
          offset: params.offset,
          filter: planFilterToServerFormat(params.filter),
        },
      },
    );
  };

  const getPlan = async (
    params: {
      id: PlanId
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<Plan>(
      `/api/plans/${params.id}`,
      config,
    );
  };


  return {
    getPlan,
    getPlans,
  };
};
