import styles from "./ModalFooter.module.scss";
import type { CSSProperties, ReactNode } from "react";
import React from "react";
import cn from "classnames";
import { Flex } from "@gs/uiKit/layout/Flex";
import { ModalFooterButton } from "./ModalFooterButton/ModalFooterButton";

export type ModalFooterProps = {
  children: ReactNode
  className?: string
  style?: CSSProperties
  shiftRight?: boolean
}

export const ModalFooter = (props: ModalFooterProps) => {
  const shiftRight = props.shiftRight ?? true;

  return (
    <Flex
      gap="middle"
      className={cn(styles.container, props.className, { [styles.__shiftRight!]: shiftRight })}
      style={props.style}
    >
      { props.children }
    </Flex>
  );
};

ModalFooter.Button = ModalFooterButton;
