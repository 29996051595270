import type { SenderProfileId } from "@gs/core/domain/SenderProfile/SenderProfile";
import { senderProfileApi } from "../senderProfileApi";
import { createFindOrFetchForDomainHook } from "../../_utils/createFindOrFetchForDomainHook";
import { useSenderProfileStore } from "../senderProfileStore";

export const useFindOrFetchSenderProfile = createFindOrFetchForDomainHook({
  fetch: (ids: SenderProfileId[]) => senderProfileApi.getSenderProfiles({ limit: ids.length, offset: 0, filter: { id: ids } }),
  useStore: useSenderProfileStore,
});

export const useFindOrFetchSenderProfileWithDeleted = createFindOrFetchForDomainHook({
  fetch: (ids: SenderProfileId[]) => senderProfileApi.getSenderProfiles({ limit: ids.length, offset: 0, filter: { id: ids, withDeleted: true } }),
  useStore: useSenderProfileStore,
});
