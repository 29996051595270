import type { CSSProperties } from "react";
import { useMemo } from "react";
import { Popover } from "@gs/uiKit/dataDisplay/Popover";
import { TeamMenuContent } from "./TeamMenuContent";
import { useCurrentTeam } from "feature/session/hook/useCurrentTeam";
import { TeamAvatar } from "domain/team/component/TeamAvatar";
import { mainLayoutService } from "feature/mainLayout/MainLayoutService";
import { Flex } from "@gs/uiKit/layout/Flex";
import { Typography } from "@gs/uiKit/general/Typography";
import { RightOutlined } from "@ant-design/icons";


export const TeamMenu = (props: { className?: string; style?: CSSProperties }) => {
  const isSideMenuOpen = mainLayoutService.useStore((s) => s.isSideMenuOpen);
  const profileMenuContent = useMemo(() => <TeamMenuContent />, []);
  const currentTeam = useCurrentTeam();

  if (!currentTeam) return null;

  return (
    <Popover
      content={profileMenuContent}
      arrow={false}
      placement="rightTop"
    >
      <Flex
        gap="middle"
        align="center"
        style={{
          width: isSideMenuOpen ? "100%" : undefined,
          padding: isSideMenuOpen ? "0 var(--padding-sm)" : undefined,
        }}
      >
        <TeamAvatar
          teamId={currentTeam.id}
          {...props}
        />
        {isSideMenuOpen && (
          <>
            <Typography.Text
              ellipsis
              style={{ flex: "1 1 0" }}
            >
              {currentTeam.name}
            </Typography.Text>

            <RightOutlined />
          </>
        )}
      </Flex>
    </Popover>
  );
};
