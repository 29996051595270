import { Switch, Route } from "react-router-dom";
import { AsyncMessengerPage } from "./AsyncMessengerPage";
import NotFoundRoute from "components/routes/NotFound";
import { messengerPath } from "./path";

export default () => (
  <Switch>
    <Route
      path={messengerPath}
      component={AsyncMessengerPage}
    />
    <Route component={NotFoundRoute} />
  </Switch>
);

export { messengerPath as path } from "./path";
