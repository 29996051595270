import { useMemo } from "react";
import { SET_TEAM_URL_PARAM_NAME } from "@gs/core/const/teamIdHook";
import type { Link } from "react-router-dom";
import { useCurrentTeam } from "feature/session/hook/useCurrentTeam";

export const useToWithTeamId = (to: Parameters<typeof Link>[0]["to"], target?: string) => {
  const teamId = String(useCurrentTeam()?.id || "");

  const newTo: typeof to = useMemo(() => {
    if (!teamId) return to;
    // if (target !== "_blank") return to; // not only for blanks for cases like (ctrl + leftClick)

    let res = to;
    if (typeof to === "string") {
      let url: URL;
      try {
        url = new URL(to);
        if (url.origin !== location.origin) {
          return to;
        }
      } catch {
        url = new URL(location.origin + to);
      }
      url.searchParams.set(SET_TEAM_URL_PARAM_NAME, teamId);
      res = url.href.replace(url.origin, "");
    } else if (typeof to !== "function") {
      const searchParams = new URLSearchParams(to.search);
      searchParams.set(SET_TEAM_URL_PARAM_NAME, teamId);
      res = {
        ...to,
        search: searchParams.toString(),
      };
    } else {
      res = (location) => {
        const theTo = to(location);

        const searchParams = new URLSearchParams((theTo.search || "").toString());
        searchParams.set(SET_TEAM_URL_PARAM_NAME, teamId);
        return {
          // @ts-ignore
          ...theTo,
          search: searchParams.toString(),
        };
      };
    }

    return res;
  }, [teamId, to, target]);

  return newTo;
};
