import type { Plan, PlanId } from "@gs/core/domain/Plan/Plan";


export const testPlanId = "786935" as PlanId;

export const mainPlansInfo: Array<{
  description: string
  monthlyPlanId: PlanId
  yearlyPlanId: PlanId
}> = [
  {
    // Growth
    description: "The safest cloud automation for small projects and growing agencies",
    monthlyPlanId: "897485" as PlanId,
    yearlyPlanId: "897489" as PlanId,
  },
  {
    // Pro
    description: "The most powerful cloud automation designed for PRO teams",
    monthlyPlanId: "897487" as PlanId,
    yearlyPlanId: "897490" as PlanId,
  },
  {
    // Scale
    description: "The best way to scale outbound channel and maximize ROI",
    monthlyPlanId: "897488" as PlanId,
    yearlyPlanId: "897491" as PlanId,
  },
];

export const allMainPlanIds = mainPlansInfo.flatMap((item) => [item.monthlyPlanId, item.yearlyPlanId]);

export const getIsCustomPlanId = (planId: PlanId) => ![...allMainPlanIds, "trial"].includes(planId);
