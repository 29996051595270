import parentPath from "../path";

export const apiKeysPath = `${parentPath}/api-keys`;

export const apiKeysPageQueryParams = {
  PAGE: "page",
  PAGE_SIZE: "pageSize",
  SEARCH: "q",
} as const;

export const getLinkToApiKeys = (args?: {
  query?: string
  page?: number
  pageSize?: number
}) => {
  if (!args) return apiKeysPath;
  const searchParams = new URLSearchParams();
  if ("query" in args) {
    searchParams.set(apiKeysPageQueryParams.SEARCH, JSON.stringify(args.query!));
  }
  if ("page" in args) {
    searchParams.set(apiKeysPageQueryParams.PAGE, JSON.stringify(args.page!));
  }
  if ("pageSize" in args) {
    searchParams.set(apiKeysPageQueryParams.PAGE_SIZE, JSON.stringify(args.pageSize!));
  }
  const getParamsString = searchParams.toString();

  if (getParamsString) {
    return `${apiKeysPath}?${getParamsString}`;
  }

  return apiKeysPath;
};

export default apiKeysPath;
