import { NavLink } from "react-router-dom";
import { useToWithTeamId } from "./useToWithTeamId";

export const InnerNavLink: typeof NavLink = ({ to, target, ...rest }) => {
  // @ts-ignore
  const newTo = useToWithTeamId(to, target);

  return (
    // @ts-ignore
    <NavLink
      to={newTo}
      target={target}
      {...rest}
    />
  );
};
