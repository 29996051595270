import basePath from "../path";
import type { PipelineStageObject } from "@gs/core/domain/PipelineStage/PipelineStageObject";

export const pipelineStagesPath = `${basePath}/pipeline-stages`;
export const objectParamName = "object";
export const pipelineStagesByObjectPath = `${pipelineStagesPath}/:${objectParamName}`;

export const pipelineStageObjectUrlParamMap = {
  lead: "contact",
  company: "account",
} as const satisfies Record<PipelineStageObject, string>;

export const getPipelineStageObjectByUrlParam = (urlParam: string): PipelineStageObject | null => {
  for (const [thePipelineStageObject, theUrlParam] of Object.entries(pipelineStageObjectUrlParamMap)) {
    if (theUrlParam === urlParam) return thePipelineStageObject as PipelineStageObject;
  }

  return null;
};

export const getLinkToPipelineStagesPage = (args?: {
  pipelineStageObject?: PipelineStageObject
}) => {
  if (!args) return pipelineStagesPath;
  if (!args.pipelineStageObject) return pipelineStagesPath;

  return pipelineStagesByObjectPath.replace(new RegExp(`:${objectParamName}\\b`), pipelineStageObjectUrlParamMap[args.pipelineStageObject]);
};

export default pipelineStagesPath;
