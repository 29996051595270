import { path as apiKeysPath, AsyncApiKeysPage } from "./ApiKeys";
import WebhooksPage, { path as webhooksPath } from "./Webhooks";
import { WebhooksNavigation } from "./Webhooks/WebhooksNavigation/WebhooksNavigation";
import LinkedinFilters, { path as linkedinFiltersPath } from "./LinkedinFilters";
import TeamMembers, { path as teamMembersPath } from "./Team";
import CustomFields, { path as customFieldsPath } from "./CustomFields";
import PipelineStages, { path as pipelineStagesPath } from "./PipelineStages";
import type { ComponentType } from "react";

type RouteDescriptions = {
  path: string // e.g. /stages/:id used to highlight menu item as `active`
  to?: string // e.g. /stages or /stages/123. direct link without variables. if empty than `path` will be used (should be NOT dynamic)
  label: string
  body: ComponentType
  header?: ComponentType | string // if string -> <ContentHeader.Title> {string} </ContentHeader.Title>. if empty -> use label as string
  subRoutes?: RouteDescriptions[]
}

export const settingsSubRoutes: RouteDescriptions[] = [
  {
    path: teamMembersPath,
    label: "Team Members",
    body: TeamMembers,
  },
  {
    path: customFieldsPath,
    label: "Custom Fields",
    body: CustomFields,
  },
  {
    path: pipelineStagesPath,
    label: "Pipeline Stages",
    body: PipelineStages,
  },
  {
    path: webhooksPath,
    label: "Webhooks",
    body: WebhooksPage,
    header: WebhooksNavigation,
  },
  {
    path: apiKeysPath,
    label: "API Keys",
    body: AsyncApiKeysPage,
  },
  {
    path: linkedinFiltersPath,
    label: "LinkedIn Filters",
    body: LinkedinFilters,
  },
];
