import { matchPath } from "react-router-dom";
import type { User } from "@gs/core/domain/User/User";
import type { Team, TeamId } from "@gs/core/domain/Team/Team";
import { teamApi } from "domain/team/teamApi";
import history from "services/browserHistory";
import { getIsWhiteLabel } from "store/whiteLabel/selectors";
import store from "store/store";
import { identifyUser } from "services/analytics";
import { bugsnagSetUser } from "services/bugsnag";
import { currentUserApi } from "./currentUserApi";
import { userStore } from "domain/user/userStore";
import { paths as flowPaths } from "components/routes/flowsGroup/const";
import { paths as templatePaths } from "components/routes/Templates";
import { paths as senderProfilePaths } from "components/routes/SenderProfileGroup/paths";
import { sessionStore, useSessionStore } from "feature/session/sessionStore";
import { dataBus } from "services/dataBus";
import { paths as crmPaths } from "components/routes/crmGroup/const";
import { messengerPath, getLinkToMessenger } from "components/routes/Messenger/path";
import { findOrFetchTeam } from "domain/team/util/findOrFetchTeam";
import { findOrFetchSubscription } from "domain/subscription/util/findOrFetchSubscription";
import { modalService } from "feature/modal/ModalService";
import subscriptionsPath from "components/routes/UserSettingsGroup/Subscriptions/path";
import { planApi } from "domain/plan/planApi";
import { subscriptionApi } from "domain/subscription/subscriptionApi";
import { licenseApi } from "domain/license/licenseApi";

export class SessionService {
  public readonly store = sessionStore;
  public readonly useStore = useSessionStore;
  public readonly SEARCH_QUERY_TEAM_ID_KEY = "set_team_id";
  private readonly SESSION_STORAGE_TEAM_ID_KEY = "gs-team-id";
  private readonly LOCAL_STORAGE_TEAM_ID_KEY = "gs-team-id";

  constructor() {
    dataBus.subscribe("currentUser:logout", () => this.onLogout());
    history.listen(() => {
      const teamIdFromQuery = this.removeTeamFromQuery();
      if (teamIdFromQuery == this.store.getState().teamId) return;
      this.validateTeamId(teamIdFromQuery).then((valid) => {
        if (!valid) return;
        this.changeTeam(teamIdFromQuery!);
      });
    });
  }

  public async loadCurrentUser() {
    if (this.store.getState().status === "loading") return;
    this.store.setState({ status: "loading" });
    try {
      const { data: user } = await currentUserApi.getCurrentUser();
      await this.setCurrentUser(user);
    } catch {
      this.store.setState({ status: "unauthorized" });
    }
  }

  public async signUp(...args: Parameters<typeof currentUserApi.signUp>) {
    this.store.setState({ status: "loading" });
    try {
      const { data } = await currentUserApi.signUp(...args);
      await this.setCurrentUser(data.user);
    } catch (error) {
      this.store.setState({ status: "unauthorized" });
      throw error;
    }
  }

  public async signIn(args: {email: string; password: string}) {
    await currentUserApi.signIn(args);
    await this.loadCurrentUser();
  }

  public async signOut() {
    await currentUserApi.signOut();
    this.onLogout();
  }

  public async changeTeam(newTeamId: TeamId) {
    const { teamId } = this.store.getState();
    if (teamId == newTeamId) return;
    sessionStorage.setItem(this.SESSION_STORAGE_TEAM_ID_KEY, String(newTeamId));
    this.store.setState({ teamId: newTeamId });
    currentUserApi.setLastTeamId({ teamId: newTeamId });
    this.redirectFromTeamSpecificRoutes();
    window.location.reload();
  }

  // TODO: Reimplement. session service shouldn't now anything about routes
  public redirectFromTeamSpecificRoutes() {
    const specificRoutes: Array<{specificRoute: string; redirectRoute: string; excludeRoutes?: string[]}> = [
      {
        specificRoute: flowPaths.flowDetails,
        redirectRoute: flowPaths.automation,
        excludeRoutes: [flowPaths.templates, flowPaths.archive, flowPaths.automation],
      },
      {
        specificRoute: templatePaths.templateDetails,
        redirectRoute: templatePaths.templatesStatic,
      },
      {
        specificRoute: senderProfilePaths.SPDetails,
        redirectRoute: senderProfilePaths.SPRoot,
      },
      { // clear filter
        specificRoute: crmPaths.contacts,
        redirectRoute: crmPaths.contacts,
      },
      { // clear filter
        specificRoute: crmPaths.companies,
        redirectRoute: crmPaths.companies,
      },
      { // clear filter
        specificRoute: messengerPath,
        redirectRoute: getLinkToMessenger(),
      },
    ];

    for (const { specificRoute, redirectRoute, excludeRoutes } of specificRoutes) {
      if (!matchPath(window.location.pathname, specificRoute)) continue;
      if (excludeRoutes && matchPath(window.location.pathname, excludeRoutes)) continue;

      history.push(redirectRoute);
      return;
    }
    // remove get params
    history.push(window.location.pathname);
  }

  private onLogout() {
    this.store.setState({
      teamId: null,
      userId: null,
      status: "unauthorized",
    });
  }

  private async setCurrentUser(user: User) {
    userStore.setState({ [user.id]: user });
    const team = await this.getCurrentTeam(user);
    sessionStorage.setItem(this.SESSION_STORAGE_TEAM_ID_KEY, String(team?.id));
    localStorage.setItem(this.LOCAL_STORAGE_TEAM_ID_KEY, String(team?.id));
    this.store.setState({
      userId: user.id,
      teamId: team?.id,
      clusterId: team?.cluster_id,
      status: "authorized",
    });


    const isWhiteLabel = getIsWhiteLabel(store.getState());
    const userName = [user.first_name, user.last_name].filter(Boolean).join(" ");
    identifyUser(user.id, { email: user.email });
    bugsnagSetUser({
      id: user.id,
      name: userName,
      email: user.email,
    });


    // @ts-ignore
    window.hj?.("identify", user.id, {
      email: user.email,
      created_at: user.created_at,
    });


    if (!isWhiteLabel) {
      const licenseRes = team?.license_uuid && await licenseApi.getLicense({ id: team.license_uuid }).catch(() => null);
      const planRes = licenseRes?.data.plan_id && await planApi.getPlan({ id: licenseRes.data.plan_id }).catch(() => null);
      const licensesRes = await licenseApi.getLicenses({ offset: 0, limit: 1 }).catch(() => null);

      // @ts-ignore
      window.Intercom?.("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "w53hsque",
        name: userName,
        email: user.email,
        team_id: team?.id,
        user_id: `${user.id}:${team?.id}`,
        created_at: user.created_at,

        team_plan_name: planRes?.data.name,
        user_licenses_count: licensesRes?.data.total,
        login_as_link: team && `https://amazing.getsales.io/flows/c${team.cluster_id}/monitoring/login-as/${team.id}`,
        // custom_attributes: {
        //   team_plan_name: planRes?.data.name,
        //   user_licenses_count: licensesRes?.data.total,
        //   login_as_link: team && `https://amazing.getsales.io/flows/c${team.cluster_id}/monitoring/login-as/${team.id}`,
        // },
      });
    }
  }

  private removeTeamFromQuery(): TeamId | null {
    const sp = new URLSearchParams(window.location.search);
    if (sp.has(this.SEARCH_QUERY_TEAM_ID_KEY)) {
      const teamIdFromQuery = sp.get(this.SEARCH_QUERY_TEAM_ID_KEY);
      sp.delete(this.SEARCH_QUERY_TEAM_ID_KEY);

      history.replace({
        search: sp.toString(),
      });

      return Number(teamIdFromQuery) as TeamId || null;
    }

    return null;
  }

  private async getCurrentTeam(currentUser: User): Promise<Team | null> {
    const currentTeamId = await (async () => {
      const teamIdFromQuery = this.removeTeamFromQuery();
      if (await this.validateTeamId(teamIdFromQuery)) {
        return teamIdFromQuery;
      }
      const teamIdFromSessionStorage = sessionStorage.getItem(this.SESSION_STORAGE_TEAM_ID_KEY);
      if (await this.validateTeamId(teamIdFromSessionStorage)) {
        return Number(teamIdFromSessionStorage) as TeamId;
      } else {
        sessionStorage.removeItem(this.SESSION_STORAGE_TEAM_ID_KEY);
      }
      const teamIdFromLocalStorage = localStorage.getItem(this.LOCAL_STORAGE_TEAM_ID_KEY);
      if (await this.validateTeamId(teamIdFromLocalStorage)) {
        return Number(teamIdFromLocalStorage) as TeamId;
      } else {
        localStorage.removeItem(this.LOCAL_STORAGE_TEAM_ID_KEY);
      }

      if (await this.validateTeamId(currentUser.config.latest_team_id ?? null)) {
        return currentUser.config.latest_team_id ?? null;
      }

      try {
        const { data: { data } } = await teamApi.getTeams({ limit: 1, offset: 0 });
        return data.at(0)?.id || null;
      } catch {
        return null;
      }
    })();

    if (!currentTeamId) return null;

    const currentTeam = await findOrFetchTeam(currentTeamId);

    this.checkSubscription(currentTeam);

    return currentTeam;
  }

  private async checkSubscription(team: Team) {
    // if (team.created_at < "2024-09-01") return;
    // const subscription = team.subscription_id && await findOrFetchSubscription(team.subscription_id);
    // if (!subscription || !team.license_uuid || !["past_due", "active"].includes(subscription.status)) {
    if (!team.license_uuid) {
      history.push(subscriptionsPath);
      modalService.openModal("teamChangeSubscriptionModalId", { teamId: team.id });
    }
  }

  private async validateTeamId(teamId: TeamId | string | null) {
    if (teamId == null) return false;
    if (teamId === "null") return false;
    if (teamId === "undefined") return false;
    if (!teamId) return false;

    try {
      await teamApi.getTeam({ id: teamId as unknown as TeamId });
      return true;
    } catch {
      return false;
    }
  }
}


export const sessionService = new SessionService();
