import { sessionService } from "feature/session/SessionService";
import { useUserStore } from "domain/user/userStore";
import { useEffect } from "react";

export const useCurrentUser = () => {
  const currentUserId = sessionService.useStore((s) => s.userId);
  const currentUserStatus = sessionService.useStore((s) => s.status);
  const currentUser = useUserStore((users) => currentUserId && users[currentUserId]);

  useEffect(() => {
    if (currentUser) return;

    sessionService.loadCurrentUser();
  }, [currentUserId]);

  return {
    currentUser,
    currentUserStatus,
  };
};
