import { getBaseAxios } from "services/axios";
import { planStore } from "./planStore";
import { createPlanApi } from "@gs/core/domain/Plan/createPlanApi";
import type { Plan, PlanId } from "@gs/core/domain/Plan/Plan";

export const planApi = (() => {
  const axiosInstance = getBaseAxios("idV2");

  const coreApi = createPlanApi(axiosInstance);

  const getPlans: typeof coreApi.getPlans = async (...args) => {
    const result = await coreApi.getPlans(...args);

    planStore.setState(
      result.data.data.reduce<Record<PlanId, Plan>>((acc, plan) => {
        acc[plan.external_id as any] = plan;
        return acc;
      }, {}),
    );

    return result;
  };

  const getPlan: typeof coreApi.getPlan = async (...args) => {
    const result = await coreApi.getPlan(...args);

    planStore.setState({
      [result.data.external_id]: result.data,
    });

    return result;
  };

  return {
    getPlan,
    getPlans,
  };
})();
