import Modal from "components/common/Modal/Modal";
import React, { useEffect } from "react";
import { reloadIfNewVersion } from "feature/appVersion";

export const ModalLoadingError = (props: {closeModal: () => void}) => {
  useEffect(reloadIfNewVersion, []);

  return (
    <Modal.Container Tag="div">
      <Modal.Header
        header="Something went wrong"
        onClose={props.closeModal}
      />

      <div>
        Sorry we can't load this window
      </div>
    </Modal.Container>
  );
};
