import type { IsoDate, Mixed, MixedNumberOrDate } from "../../commonTypes";
import type { Plan, PlanId } from "./Plan";

export type PlanFilter = {
  id?: Mixed<PlanId>
  name?: Mixed<string>
  type?: Mixed<Plan["type"]>
  isPrimary?: Mixed<boolean>
  createdAt?: MixedNumberOrDate<IsoDate>
  updatedAt?: MixedNumberOrDate<IsoDate>
}

export const planFilterToServerFormat = (filter?: PlanFilter) => filter && ({
  external_id: filter.id,
  name: filter.name,
  type: filter.type,
  is_primary: filter.isPrimary,
  created_at: filter.createdAt,
  updated_at: filter.updatedAt,
});
