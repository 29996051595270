import { senderProfileApi } from "domain/senderProfile/senderProfileApi";
import type { SenderProfile } from "@gs/core/domain/SenderProfile/SenderProfile";

export const loadAllSenderProfiles = async () => {
  const buffer: SenderProfile[] = [];
  const limit = 100;
  let offset = 0;
  let hasMore = true;

  while (hasMore) {
    const { data } = await senderProfileApi.getSenderProfiles({ offset, limit });
    hasMore = data.has_more;
    offset += limit;
    buffer.push(...data.data);
  }

  return buffer;
};
