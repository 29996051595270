import { Redirect, Route, Switch } from "react-router-dom";
import { SettingsNavigation } from "./SettingsNavigation/SettingsNavigation";
import { path as teamPath } from "./Team";
import NotFoundRoute from "../NotFound";
import rootPath from "./path";
import { ContentHeader } from "feature/mainLayout/component/ContentHeader/ContentHeader";
import { ContentBody } from "feature/mainLayout/component/ContentBody/ContentBody";
import { settingsSubRoutes } from "./subRoutes";

export { default as path } from "./path";

export default () => {
  return (
    <>
      <ContentHeader>
        <Switch>
          { settingsSubRoutes.map((subRoute) => (
            <Route
              key={subRoute.path}
              path={subRoute.path}
            >
              {() => {
                if (!subRoute.header) {
                  return <ContentHeader.Title>{subRoute.label}</ContentHeader.Title>;
                }
                if (typeof subRoute.header === "string") {
                  return <ContentHeader.Title>{subRoute.header}</ContentHeader.Title>;
                }

                return <subRoute.header />;
              }}
            </Route>
          ))}
        </Switch>
      </ContentHeader>
      <ContentBody style={{ display: "flex", gap: "var(--padding)" }}>
        <SettingsNavigation />

        <div style={{ width: 0, flexGrow: 1 }}>
          <Switch>
            <Route
              exact
              path={rootPath}
            >
              <Redirect to={teamPath} />
            </Route>
            { settingsSubRoutes.map((subRoute) => (
              <Route
                key={subRoute.path}
                path={subRoute.path}
                component={subRoute.body}
              />
            ))}
            <Route component={NotFoundRoute} />
          </Switch>
        </div>
      </ContentBody>
    </>
  );
};
