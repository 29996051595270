import type { AxiosRequestConfig, AxiosInstance } from "axios";
import type { ApiListResponse, OrderType } from "../../commonTypes";
import type { LicenseFilter } from "./LicenseFilter";
import { licenseFilterToServerFormat } from "./LicenseFilter";
import type { License, LicenseId } from "./License";
import type { OnlyPropertyKey } from "../../commonTypes";


export const createLicenseApi = (axios: AxiosInstance) => {
  const getLicenses = (
    params: {
      filter?: LicenseFilter
      limit: number
      offset: number
      orderField?: string
      orderType?: OrderType
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<ApiListResponse<License>>(
      "/api/licenses",
      {
        ...config,
        params: {
          order_field: params.orderField,
          order_type: params.orderType,
          limit: params.limit,
          offset: params.offset,
          filter: licenseFilterToServerFormat(params.filter),
        },
      },
    );
  };

  const getLicense = async (
    params: {
      id: LicenseId
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<License>(
      `/api/licenses/${params.id}`,
      config,
    );
  };

  const getGroupCountsOfLicense = async<LicenseField extends keyof License> (
    params: {
      filter?: LicenseFilter
      groupField: LicenseField
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<Record<OnlyPropertyKey<License[LicenseField]>, number>>(
      "/api/licenses/group-counts",
      {
        filter: licenseFilterToServerFormat(params.filter),
        group_field: params.groupField,
      },
      config,
    );
  };

  return {
    getLicense,
    getLicenses,
    getGroupCountsOfLicense,
  };
};
