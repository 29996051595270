import parentPath from "../path";
import type { WebhookId } from "@gs/core/domain/Webhook/Webhook";

export const webhooksHistoryPath = `${parentPath}/history`;

export default webhooksHistoryPath;


export const webhooksHistoryPageQueryParams = {
  WEBHOOK: "webhook",
} as const;

export const getLinkToWebhooksHistoryPage = (args?: {
  webhookId?: WebhookId
}) => {
  if (!args) return webhooksHistoryPath;
  const searchParams = new URLSearchParams();
  if ("webhookId" in args) {
    searchParams.set(webhooksHistoryPageQueryParams.WEBHOOK, JSON.stringify(args.webhookId!));
  }
  const getParamsString = searchParams.toString();

  if (getParamsString) {
    return `${webhooksHistoryPath}?${getParamsString}`;
  }

  return webhooksHistoryPath;
};
