import { webhooksTablePath } from "../WebhooksTablePage/path";
import { webhooksHistoryPath } from "../WebhooksHistoryTablePage/path";
import { ContentHeader } from "feature/mainLayout/component/ContentHeader/ContentHeader";

export const WebhooksNavigation = () => {
  return (
    <ContentHeader.Tabs.Navigation
      tabs={[
        {
          exact: true,
          label: "Webhooks",
          path: webhooksTablePath,
        },
        {
          label: "History",
          path: webhooksHistoryPath,
        },
      ]}
    />
  );
};
