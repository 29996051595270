import basePath from "../path";
import type { CustomFieldObject } from "@gs/core/domain/CustomField/CustomFieldObject";

export const customFieldsPath = `${basePath}/custom-fields`;
export const objectParamName = "object";
export const customFieldsByObjectPath = `${customFieldsPath}/:${objectParamName}`;

export const customFieldObjectUrlParamMap = {
  lead: "contact",
  company: "account",
  sender_profile: "sender-profile",
} as const satisfies Record<CustomFieldObject, string>;

export const getCustomFieldObjectByUrlParam = (urlParam: string): CustomFieldObject | null => {
  for (const [theCustomFieldObject, theUrlParam] of Object.entries(customFieldObjectUrlParamMap)) {
    if (theUrlParam === urlParam) return theCustomFieldObject as CustomFieldObject;
  }

  return null;
};

export const getLinkToCustomFieldsPage = (args?: {
  customFieldObject?: CustomFieldObject
}) => {
  if (!args) return customFieldsPath;
  if (!args.customFieldObject) return customFieldsPath;

  return customFieldsByObjectPath.replace(new RegExp(`:${objectParamName}\\b`), customFieldObjectUrlParamMap[args.customFieldObject]);
};

export default customFieldsPath;
