import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import { ModuleLoaderError } from "components/common/ModuleLoaderError";

export { default as path } from "./path";

export const AsyncApiKeysPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'ApiKeysPage' */ "./ApiKeysPage")
      .catch( (e) => {
        console.log("Api Keys Page loading err", e);
        return ModuleLoaderError;
      });
  },
  // @ts-ignore
  loading: Loading,
});

export default AsyncApiKeysPage;

