import { Redirect, Route, Switch } from "react-router-dom";
import User, { path as userPath } from "./User";
import TeamsManagement, { path as teamsPath } from "./TeamsManagement";
import { SubscriptionsPage, path as subscriptionsPath } from "./Subscriptions";
import NotFoundRoute from "../NotFound";
import rootPath from "./path";
import Navigation from "./Navigation/Navigation";
import { ContentHeader } from "feature/mainLayout/component/ContentHeader/ContentHeader";
import { ContentBody } from "feature/mainLayout/component/ContentBody/ContentBody";
import { useSelector } from "react-redux";
import { getIsWhiteLabel } from "store/whiteLabel/selectors";

export { default as path } from "./path";

export default () => {
  const isWhiteLabel = useSelector(getIsWhiteLabel);

  return (
    <>
      <ContentHeader />
      <ContentBody style={{ display: "flex", gap: "var(--padding)" }}>
        <Navigation />
        
        <div className="gs-min-w-0 gs-grow">
          <Switch>
            <Route
              exact
              path={rootPath}
            >
              <Redirect to={userPath} />
            </Route>
            <Route
              exact
              path={userPath}
              component={User}
            />
            {!isWhiteLabel && (
              <Route
                path={subscriptionsPath}
                component={SubscriptionsPage}
              />
            )}
            <Route
              exact
              path={teamsPath}
              component={TeamsManagement}
            />
            <Route component={NotFoundRoute} />
            {" "}
            {/* 404 */}
          </Switch>
        </div>
      </ContentBody>
    </>
  );
};
